import { http } from "../ConnectApi/ConnectApi";

export async function loginController(data: { email: String; password: string}) {
  try {
    const response = await http.post('/auth/login', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
