import { http } from "../ConnectApi/ConnectApi";

export async function ValidateEmailController(data: { 
  email: string;
  emailToken: string;
}) {
  try {
    const response = await http.patch('/auth/validate', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
