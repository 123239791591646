import { http } from "../ConnectApi/ConnectApi";

export async function signUpController(data: { 
  email: string;
  password: string;
  confirmPassword: string;
  country: string;
  accountType: string;

}) {
  try {
    const response = await http.post('/auth/create', data, {
      withCredentials: true
    });

    return response;
  } catch (error: any) {
    console.error('Error during request:', error.response?.data || error.message);
    throw error;
  }
}
