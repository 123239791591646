import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from "./client/routes";
import { CurrencyProvider } from './context/Currency/CurrencyContext';
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <div className="App">
      <ChakraProvider>
       <CurrencyProvider>
      <AppRoutes />
      </CurrencyProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
