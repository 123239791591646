import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'flowbite-react';
import { useEffect, useRef, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LogoGreen from "../../components/LogoGreen";
import AuthCode from 'react-auth-code-input';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { ValidateEmailController } from "../../controller/ValidateEmailController/ValidateEmailController";

const inputItems = ["code1", "code2", "code3", "code4", "code5", "code6"];

export const ValidateEmail = () => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();
    const [result, setResult] = useState<string>("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState<string>("");
    const { t, i18n} = useTranslation();

    const handleOnChange = (res: string) => {
        setResult(res);
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            navigate('/');
        }
    }, [navigate]);

    function returnToLogin() {
        navigate('/');
    }

    const handleRegister = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
    
        const requestData = {
            email: email,
            emailToken: result
        };
    
        try {
            const response = await ValidateEmailController(requestData);
    
            if (response.status===201) {
                toast.success('Account created successfully');
                setTimeout(() => {
                    navigate('/');
                }, 3000);
                return;
            }
    
        } catch (error: any) {
            console.error("Error in handleSubmit:", error.response?.data || error.message);
            setError(error.response?.data?.error || "An error occurred while processing the transaction.");
            setLoading(false);
        }
        setLoading(false);
    };

    const isCodeComplete = result.length === inputItems.length;

    return (
        <div className='flex justify-center items-center h-screen max-w-[382px] mx-auto'>
            <form ref={formRef} onSubmit={handleRegister}>
                <div className="mb-10">
                    <LogoGreen />
                </div>
                <Button onClick={returnToLogin} className="mx-auto text-black flex items-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon className="text-black mr-1" icon={faArrowLeft} />
                        <span className="text-black text-xl">{t('return_login')}</span>
                    </div>
                </Button>
                <h1 className="text-black text-2xl font-bold mb-3">{t('confirm_email')}</h1>
                <p>{t('enter_code_text')}</p>
                <div className="flex justify-center items-center my-3">
                    <AuthCode 
                        length={6} 
                        containerClassName="flex justify-center gap-2"
                        inputClassName="w-10 h-10 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={handleOnChange} 
                    />
                </div>
                <Button type="submit" disabled={!isCodeComplete || loading} className={`w-[90%] flex justify-center items-center mx-auto px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10 ${!isCodeComplete || loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {loading ? t('loading') : t('confirm_code')}
                </Button>
                {error && <div className="text-red-500 mt-2">{error}</div>}
            </form>
            <ToastContainer />
        </div>
    );
};
