import React, { useState, FormEvent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTimes, faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import LogoGreen from '../../components/LogoGreen';
import { TermsAndServicesCheckBox } from './TermsAndServices/TermsAndServices';
import InputModel, { Field } from '../../components/Input/InputModel/InputModel';
import { usePasswordValidation } from '../../components/hooks/Password/usePasswordValidation';
import { useForm, Controller } from 'react-hook-form';
import TextModel from '../../components/Text/text';
import { countries} from '../../../src/contants/Countries/countries'
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { signUpController } from '../../controller/SignUpController/SignUpController';



interface SignupFormProps {
    emailLogin: string;
}

const SignupForm: React.FC<SignupFormProps> = ({ emailLogin }) => {
    const { control, handleSubmit, formState: { errors }, register } = useForm();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [country, setCountry] = useState('');
    const [countryValue, setCountryValue] = useState('');
    const { t, i18n} = useTranslation();
    const navigate = useNavigate();
    const { customErrors, handlePasswordChange, handleConfirmPasswordChange } = usePasswordValidation();
    const [selectedType, setSelectedType] = useState<string>('');
    useEffect(() => {
        setEmail(emailLogin);
    }, [emailLogin]);

   
    const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        handlePasswordChange(e);
    };

    const handleConfirmPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        handleConfirmPasswordChange(e);
    };

    const fields: Field[] = [
        { label: t('password'), type: "password", placeholder: "*********", name: "password", onChange: handlePasswordInputChange },
        { label: t('confirm_password'), type: "password", placeholder: "*********", name: "confirmPassword", onChange: handleConfirmPasswordInputChange },
    ];

    const submitSignUp = async () => {
        setLoading(true);
        setError('');

        const requestData = {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            country: country,
            accountType: selectedType 
        };

        try {
     
            const data = await signUpController(requestData);
            if (data.status===201) {
                localStorage.setItem('email', email);
                navigate('/validate-email');
                return;
            }
        } catch (error: any) {
            console.error("Error in handleSubmit:", error.response?.data || error.message);
            setError(error.response?.data?.error || "An error occurred while processing the transaction.");
            setLoading(false);
        }
        setLoading(false);
    };

    const handleSignup = async (e: FormEvent) => {
        e.preventDefault();
        switch (currentStep) {
            case 1:
                setCurrentStep(2);
                break;
            case 2:
                setCurrentStep(3);
                break;
                   case 3:
                await submitSignUp();
                break;
            default:
                console.log('Passo inválido');
        }
    };


    function Step1Valid() {
     
            return email && country;
      
    }

    function isDisabled() {
        return (!selectedType && currentStep === 1) || (!Step1Valid() && currentStep === 2) || loading || (customErrors.length > 0 && currentStep === 3);
    }
    const handleSelection = (type: 'INDIVIDUAL' | 'COMPANY') => {
        setSelectedType(type);
    };


    return (
        <div className='flex justify-center items-center h-screen mt-10'>
            <form onSubmit={handleSignup} className="w-[382px] max-h-[600px] bg-white rounded-lg shadow-lg p-6">
                <LogoGreen />
                <span className="font-inter font-bold text-2xl tracking-tight text-gray-800 block mb-6 mt-10">{t('signup_title')}</span>


                {currentStep === 1 && (
                    <>
    <div className="flex justify-around mb-10">
                    <div
                        className={`flex flex-col items-center p-4 border-2 rounded-lg cursor-pointer transition-all ${
                            selectedType === 'INDIVIDUAL' ? 'border-green-500' : 'border-gray-300 hover:border-green-300'
                        }`}
                        onClick={() => handleSelection('INDIVIDUAL')}
                    >
                        <FontAwesomeIcon icon={faUser} className="h-12 w-12 text-gray-500 mb-4" />
                        <span className="font-inter font-semibold text-lg">{t('individual')}</span>
                    </div>

                    <div
                        className={`flex flex-col items-center p-4 border-2 rounded-lg cursor-pointer transition-all ${
                            selectedType === 'COMPANY' ? 'border-green-500' : 'border-gray-300 hover:border-green-300'
                        }`}
                        onClick={() => handleSelection('COMPANY')}
                    >
                        <FontAwesomeIcon icon={faBuilding} className="h-12 w-12 text-gray-500 mb-4" />
                        <span className="font-inter font-semibold text-lg">{t('company')}</span>
                    </div>
                </div>
                    </>
                )}

                {currentStep === 2 && (
                    <>
                    <div className="my-3 flex-col flex">
<Controller
control={control}
{...register('country')} 
rules={{required:true}}
render={({ field }) => (

<Select
    options={countries}
    isSearchable
    ref={field.ref}
    placeholder={t('country_placeholder')}
    onChange={value => {

        if(value){
          setCountry(value?.value)
          setCountryValue(value.value.toUpperCase())
        }
 

        field.onChange(value?.label)
    }}
   
/>

)}

/> 

{errors.country && <TextModel
          addons={`text-sm`}
          color='text-red-600'
          content={errors.country.message}
      />}


</div>
                        
                        <div className="flex items-center w-full h-[52px] gap-4 mb-10 mt-10 bg-gray-100">
                            <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="email" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder={t('email_placeholder')} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    
  
                        <TermsAndServicesCheckBox />
                    </>
                )}
                {currentStep === 3 && (
                    <div className='text-left'>
                        {fields.map((item, index) => (
                            <div key={index} className="mb-4">
                                <InputModel {...item} />
                            </div>
                        ))}
                        <ul className="space-y-2">
                            {customErrors.map((error, index) => (
                                <li key={index} className="flex items-center text-red-600 text-sm">
                                    <FontAwesomeIcon icon={faTimes} className="h-4 w-4 mr-2" />
                                    <span>{error}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <Button type="submit" disabled={isDisabled()} className={`w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10 ${isDisabled() ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {loading ? 'Loading...' : t('next_button')}
                </Button>
                {error && <div className="text-red-500 mt-2">{error}</div>}
            </form>
        </div>
    );
}



export default SignupForm;
