

export const countries = [
  { value: 'AFG', label: 'افغانستان' }, // Afghanistan
  { value: 'DZA', label: 'الجزائر' }, // Algeria
  { value: 'ARG', label: 'Argentina' }, // Argentina
  { value: 'AUS', label: 'Australia' }, // Australia
  { value: 'AUT', label: 'Österreich' }, // Austria
  { value: 'BGD', label: 'বাংলাদেশ' }, // Bangladesh
  { value: 'BEL', label: 'België' }, // Belgium
  { value: 'BRA', label: 'Brasil' }, // Brazil
  { value: 'BGR', label: 'България' }, // Bulgaria
  { value: 'CAN', label: 'Canada' }, // Canada
  { value: 'CHL', label: 'Chile' }, // Chile
  { value: 'CHN', label: '中国' }, // China
  { value: 'COL', label: 'Colombia' }, // Colombia
  { value: 'HRV', label: 'Hrvatska' }, // Croatia
  { value: 'CUB', label: 'Cuba' }, // Cuba
  { value: 'CZE', label: 'Česko' }, // Czech Republic
  { value: 'DNK', label: 'Danmark' }, // Denmark
  { value: 'EGY', label: 'مصر' }, // Egypt
  { value: 'FIN', label: 'Suomi' }, // Finland
  { value: 'FRA', label: 'France' }, // France
  { value: 'DEU', label: 'Deutschland' }, // Germany
  { value: 'GRC', label: 'Ελλάδα' }, // Greece
  { value: 'HUN', label: 'Magyarország' }, // Hungary
  { value: 'IND', label: 'भारत' }, // India
  { value: 'IDN', label: 'Indonesia' }, // Indonesia
  { value: 'IRN', label: 'ایران' }, // Iran
  { value: 'IRQ', label: 'العراق' }, // Iraq
  { value: 'IRL', label: 'Éire' }, // Ireland
  { value: 'ISR', label: 'ישראל' }, // Israel
  { value: 'ITA', label: 'Italia' }, // Italy
  { value: 'JPN', label: '日本' }, // Japan
  { value: 'MEX', label: 'México' }, // Mexico
  { value: 'NLD', label: 'Nederland' }, // Netherlands
  { value: 'NZL', label: 'New Zealand' }, // New Zealand
  { value: 'NGA', label: 'Nigeria' }, // Nigeria
  { value: 'PRK', label: '조선' }, // North Korea
  { value: 'NOR', label: 'Norge' }, // Norway
  { value: 'PAK', label: 'پاکستان' }, // Pakistan
  { value: 'PER', label: 'Perú' }, // Peru
  { value: 'PHL', label: 'Pilipinas' }, // Philippines
  { value: 'POL', label: 'Polska' }, // Poland
  { value: 'PRT', label: 'Portugal' }, // Portugal
  { value: 'ROU', label: 'România' }, // Romania
  { value: 'RUS', label: 'Россия' }, // Russia
  { value: 'SAU', label: 'السعودية' }, // Saudi Arabia
  { value: 'SRB', label: 'Србија' }, // Serbia
  { value: 'SGP', label: 'Singapore' }, // Singapore
  { value: 'SVK', label: 'Slovensko' }, // Slovakia
  { value: 'SVN', label: 'Slovenija' }, // Slovenia
  { value: 'ZAF', label: 'South Africa' }, // South Africa
  { value: 'KOR', label: '대한민국' }, // South Korea
  { value: 'ESP', label: 'España' }, // Spain
  { value: 'SWE', label: 'Sverige' }, // Sweden
  { value: 'CHE', label: 'Schweiz' }, // Switzerland
  { value: 'TWN', label: '台灣' }, // Taiwan
  { value: 'THA', label: 'ไทย' }, // Thailand
  { value: 'TUR', label: 'Türkiye' }, // Turkey
  { value: 'UKR', label: 'Україна' }, // Ukraine
  { value: 'ARE', label: 'الإمارات' }, // United Arab Emirates
  { value: 'GBR', label: 'United Kingdom' }, // United Kingdom
  { value: 'USA', label: 'United States' }, // United States
  { value: 'URY', label: 'Uruguay' }, // Uruguay
  { value: 'VEN', label: 'Venezuela' }, // Venezuela
  { value: 'VNM', label: 'Việt Nam' } // Vietnam
];
