export const validatePassword = (password: string, confirmPassword: string, t: (key: string) => string): string[] => {
    const errors: string[] = [];

    if (password === '') {
        errors.push(t('password_validation_errors.empty'));
    }

    if (!/[!@#%&*]/.test(password)) {
        errors.push(t('password_validation_errors.special_char'));
    }

    if (!/[A-Z]/.test(password)) {
        errors.push(t('password_validation_errors.uppercase'));
    }

    if (password.includes("name") || password.includes("email")) {
        errors.push(t('password_validation_errors.no_name_or_email'));
    }

    if (password.length < 8) {
        errors.push(t('password_validation_errors.length'));
    }

    if (password !== confirmPassword) {
        errors.push(t('password_validation_errors.mismatch'));
    }

    return errors;
};
