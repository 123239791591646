import React, { useState, FormEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faGift } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import LogoGreen from '../../components/LogoGreen';
import { checkAccountController } from '../../controller/CheckAccountController/CheckAccountController';
import SignupForm from '../Signup/Signup';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { loginController } from '../../controller/LoginController/LoginController';
import { useTranslation } from 'react-i18next';
import { refreshTokenController } from '../../controller/RefreshTokenController/RefreshTokenController';

export default function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const dataInput = {
                email: email,
                password: password
            };

            const data = await loginController(dataInput);
            const dataRefresh = {

            };
            const refresh = await refreshTokenController(dataRefresh)
            console.log("refresh: ", refresh)

                navigate('/');
           
        } catch (error: any) {
            console.error("Error in handleSubmit:", error.response?.data || error.message);
            setError(error.response?.data?.error || t('error_message'));
            setLoading(false);
        }
        setLoading(false);
    };

    const handleVerifyEmail = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            setLoading(true);
            const data = { email: email };
            const responseAPI = await checkAccountController(data);
            if (responseAPI.status !== 200) {
                setError(responseAPI.data.data.error);
            } else if (responseAPI.data.exists) {
                setStep(2);
            } else {
                setStep(3);
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        await handleVerifyEmail(e);
        switch (step) {
            case 2:
                await handleLogin(e);
                break;
            default:
                console.log("Default case");
        }
    };

  

    return (
        <>
            {step <= 2 ? (
                <div className='flex justify-center items-center h-screen'>
                    <form onSubmit={handleSubmit} className="w-[382px] h-[503px] bg-white rounded-lg shadow-lg p-6">
                        <LogoGreen />
                        <span className="font-inter font-bold text-2xl tracking-tight text-gray-800 block mb-6 mt-10">{t('login_title')}</span>
                        <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                            <FontAwesomeIcon icon={faEnvelope} className="h-4 w-4 ml-4 text-gray-500" />
                            <input type="email" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder={t('enter_email')} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        {step === 2 && (
                            <>
                                <div className="flex items-center w-full h-[52px] gap-4 mb-4 bg-gray-100">
                                    <FontAwesomeIcon icon={faKey} className="h-4 w-4 ml-4 text-gray-500" />
                                    <input type="password" className="form-input w-full py-2 rounded-md bg-gray-100 border-transparent ring-0 focus:border-transparent focus:ring-0" placeholder={t('enter_password')} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                        
                            </>
                        )}
                        <Button type="submit" disabled={loading || !email} className="w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10">
                            {loading ? t('loading') : t('login_button')}
                        </Button>
                        {error && <div className="text-red-500 mt-2">{error}</div>}
                    </form>
                </div>
            ) : (
                <SignupForm emailLogin={email} />
            )}
            <ToastContainer/>
        </>
    );
}
