import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ptbr", // Fallback language
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    resources: {
      ptbr: {
        translation: {
          // Traduções para o Login
          login_title: "Entre na sua conta",
          enter_email: "Digite seu e-mail",
          enter_password: "Digite sua senha",
          enter_coupon: "Digite o código do cupom (Opcional)",
          login_button: "Entrar",
          loading: "Carregando...",
          error_message: "Ocorreu um erro ao processar a transação.",
          congratulations: "Parabéns! Você recebeu 10 BRLA na sua conta BRLA.",
          // Traduções para o Signup
          signup_title: "Cadastro",
          country_placeholder: "País",
          email_placeholder: "exemplo@dominio.com",
          next_button: "Próximo",
          terms_and_conditions: "Eu aceito os termos e condições",
          password_placeholder: "*********",
          confirm_password_placeholder: "*********",
          pj_checkbox_label: "Sou Pessoa Jurídica (PJ)",
          password:"Senha",
          confirm_password:"Confirmar senha",
          password_validation_errors: {
            empty: "A senha não pode estar vazia!",
            special_char: "Use pelo menos um caractere especial (@!#%&*)",
            uppercase: "Use pelo menos uma letra maiúscula",
            no_name_or_email: "Não use seu nome ou e-mail",
            length: "Sua senha deve ter pelo menos 8 caracteres",
            mismatch: "As senhas não são iguais!",
          },
          agree:'Eu concordo com',
          terms: 'termos de serviço & privacidade',
          return_login: "Retorne para o login",
          confirm_email: "Confirme seu e-mail",
          enter_code_text: "Por favor digite o código enviado para o seu e-mail. Não esqueça de checar sua caixa de spam",
          confirm_code: "Confirmar código",
          loading: "Carregando...",
          select_user_type:'Selecione o tipo de conta',
          individual: "Pessoa física",
          company: "Pessoa jurídica"
        },
      },
      en: {
        translation: {
          // Traduções para o Login
          login_title: "Log in to your account",
          enter_email: "Enter your e-mail",
          enter_password: "Enter your password",
          enter_coupon: "Enter your coupon code (Optional)",
          login_button: "Login",
          loading: "Loading...",
          error_message: "An error occurred while processing the transaction.",
          congratulations: "Congratulations! You received 10 BRLA in your BRLA Account.",
          // Traduções para o Signup
          signup_title: "Signup",
          country_placeholder: "Country",
          email_placeholder: "example@domain.com",
          next_button: "Next",
          terms_and_conditions: "I accept the terms and conditions",
          password_placeholder: "*********",
          confirm_password_placeholder: "*********",
          pj_checkbox_label: "I am a legal entity (PJ)",
          password:"Password",
          confirm_password:"Confirm Password",
          password_validation_errors: {
            empty: "Password can't be empty!",
            special_char: "Use at least one special character (@!#%&*)",
            uppercase: "Use at least one uppercase letter",
            no_name_or_email: "Don’t use your name or e-mail",
            length: "Your password must have at least 8 characters",
            mismatch: "Passwords aren't the same!",
          },
          agree: 'I agree with',
          terms: 'terms of service & privacy',
          return_login: "Return to login",
          confirm_email: "Confirm your e-mail",
          enter_code_text: "Por favor digite o código enviado para o seu e-mail. Não esqueça de checar sua caixa de spam",
          confirm_code: "Confirm code",
          loading: "Loading...",
          select_user_type:'Select account type',
          individual: "Individual",
          company: "Company"
        },
      },
    },
  });

export default i18n;
