import React from "react";
import { Checkbox, Link, Box, Text, Flex, Icon } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

export const TermsAndServicesCheckBox = () => {
  const { t } = useTranslation();

  return (
    <Flex align="center" gap={3} mb={4} p={2} bg="gray.50" borderRadius="md" boxShadow="sm">
      <Checkbox
        size="lg"
        colorScheme="blue"
        id="checkboxInput"
        iconColor="white"
        required
        _hover={{ boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.5)" }}
      />
      <Box>
        <Text fontSize="sm" color="gray.600">
          {t('agree')}{" "}
          <Link
            href="https://brla.digital/20231122_-_BRLA_Digital_-_Termos_de_Uso.pdf"
            isExternal
            color="blue.500"
            fontWeight="bold"
            _hover={{ textDecoration: "underline" }}
          >
            {t('terms')}
            <Icon as={ExternalLinkIcon} ml={1} />
          </Link>
        </Text>
      </Box>
    </Flex>
  );
};
