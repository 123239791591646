import axios, { AxiosInstance } from "axios";

const createHttp = (): AxiosInstance => {

    const SANDBOX_URL = 'https://api.brla.digital:4567/v1';
    const PRODUCTION_BASE_URL = 'https://api.betgenius.digital:10952/v2'
    
    const http = axios.create({
        baseURL: PRODUCTION_BASE_URL,
    });




    return http;
    
};

export const http = createHttp();