import { ReactNode } from "react";


export interface Text {
    
    content: string | ReactNode | any;
    color? : string;
    size? : string;
    addons? : string;
    weight?: string;
    
}

const TextModel: React.FC<Text> = 
({content,color = 'text-gray-500', size = 'text-[1em]', addons, weight = 'font-bold'}) => {


    return <p className={`${size} ${weight} ${color} ${addons}`}>{content}</p>;

}

export default TextModel;