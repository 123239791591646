import LogoBrla from "./LogoBrla/LogoBrla";

export default function LogoGreen() {
  return (
    <div className="flex">
<LogoBrla/>

    </div>  

  );
}
