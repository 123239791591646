import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoginForm from '../Pages/Login/Login';
import { ValidateEmail } from '../Pages/ValidateEmail/ValidateEmail';

const AppRoutes = () => {

    return (

        <BrowserRouter>

            <Routes>
                
                <Route path='/' element = {<LoginForm />}/>
                <Route path='/validate-email' element = {<ValidateEmail />}/>
                  

                
            </Routes>

        </BrowserRouter>
    )
}

export default AppRoutes;